import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import AboutUsLayout from '../../components/AboutUsLayout';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import SEO from '../../components/SEO/SEO';

import CreditCanadaLogo from '../../assets/images/credit_canada_logo.png';
import CanadianWomensFoundationLogo from '../../assets/images/canadian-womens-foundation-logo.png';

import './about-page.scss';

const SocialImpact = injectIntl(({ intl, location }) => {
  return (
    <AboutUsLayout intl={intl} location={location} pageName="about-us-page">
      <section className="social-impact-wrapper">
        <div className="social-impact-content">
          <h2>
            <FormattedMessage id="pages.about.social-impact.h2-0" />
          </h2>
          <p>
            <FormattedMessage id="pages.about.social-impact.p0" />
          </p>
          <h2>
            <FormattedMessage id="pages.about.social-impact.h2-1" />
          </h2>
          <p>
            <FormattedMessage id="pages.about.social-impact.p1" />
          </p>
          <h2>
            <FormattedMessage id="pages.about.social-impact.h2-2" />
          </h2>
          <p>
            <FormattedMessage id="pages.about.social-impact.p2" />
          </p>
          <img
            src={CreditCanadaLogo}
            alt={intl.formatMessage({ id: 'pages.about.social-impact.credit-canada.logo-alt-text' })}
          />
          <p>
            <Link
              to={intl.formatMessage({ id: 'pages.about.social-impact.credit-canada.link' })}
              target="_blank"
              rel="noopener noreferrer"
              title={intl.formatMessage({ id: 'pages.about.social-impact.credit-canada.link-title-text' })}
            >
              <FormattedMessage id="pages.about.social-impact.credit-canada.link-text" />
            </Link>
          </p>
          <p>
            <FormattedMessage id="pages.about.social-impact.credit-canada.p0" />
          </p>
          <img
            src={CanadianWomensFoundationLogo}
            alt={intl.formatMessage({ id: 'pages.about.social-impact.canadian-womens-foundation.logo-alt-text' })}
          />
          <p>
            <Link
              to={intl.formatMessage({ id: 'pages.about.social-impact.canadian-womens-foundation.link' })}
              target="_blank"
              rel="noopener noreferrer"
              title={intl.formatMessage({ id: 'pages.about.social-impact.canadian-womens-foundation.link-title-text' })}
            >
              <FormattedMessage id="pages.about.social-impact.canadian-womens-foundation.link-text" />
            </Link>
          </p>
          <p>
            <FormattedMessage id="pages.about.social-impact.canadian-womens-foundation.p0" />
          </p>
        </div>
      </section>
    </AboutUsLayout>
  );
});

export default SocialImpact;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.about.social-impact.page-title"
    descriptionKey="pages.about.social-impact.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
